<template>
  <div v-if="showmenu">
    <div v-if="!fetching && $auth.ready()">
      <div class="columns">
        <h1 class="title">Collections</h1>
      </div>
      <div class="columns">
        <router-link v-if="$auth.check()" tag="button" class="button is-primry" :to="{name: 'NewCollection'}">Create new collection</router-link>
      </div>
      <div v-if="collections.length === 0">
        No collections yet. Why not create one?
        <div v-if="$auth.check()">
<router-link v-if="$auth.user()" tag="button" class="button is-primry" :to="{name: 'NewCollection'}">Create new collection</router-link>


        </div>
        <div v-else>
          <a href="/login">Sign in to create a collection</a>
        </div>
      </div>
      <div v-else :class="{'has-background-white-ter': index % 2 === 0}" class="columns" v-for="(collection, index) in collections"
        :key="collection.id">
        <div class="column is-narrow has-text-left">
          <h2 class="subtitle">
            <router-link :to="'/collection/' + collection.id + '/edit'" :style="'color: ' + collection.attributes.colour">
              {{collection.attributes.name }}
            </router-link>
          </h2>
        
        </div>
        <div class="column is-narrow">  <p class="collection_count"> {{ collection.attributes.book_count }} books</p></div>
        <div class="column is-narrow"> 
          <button v-if="collection.attributes.book_count == 0" class=" button is-danger" @click="deleteCollection(collection.id)">Delete collection</button>
        </div>

      </div>
    </div>

    <div v-else>
      <img src="@/assets/ajax-loader.gif" />
    </div>
  </div>
  <div v-else>
    <Books v-if="done" :restrictCollection="this.$route.params.id" :books="books" :totalPages="totalPages"
      :fetching="fetching" :searchTerm="searchTerm" :paginationMeta="paginationMeta" @fetching="processFetch"
      @interface="handleStuff" />
  </div>
</template>
<script>
  import Books from '@/components/Books.vue'
  export default {
    data() {
      return {
        fetching: true,
        showmenu: true,
        collections: []
      }
    },
    components: {
      Books
    },
    methods: {
      deleteCollection(collId) {
        this.axios.delete('/v1/collections/' + collId)
          .then((response) => {
            if (response.status == 204) {
              this.$router.push('/collections?' + Date.new)
            }
          })
      }
    },
    mounted() {
      this.$attrs.searchTerm = null
      this.axios.get('/v1/collections')
        .then((response) => {
          this.collections = response.data.data
          this.fetching = false
        })
    }
  }
</script>